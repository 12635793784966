export const socialNetworks = {
  author: {
    GITHUB: 'https://github.com/erifranck',
    INSTAGRAM: 'https://www.instagram.com/erifranck',
    TWITTER: 'https://twitter.com/@erifranckn',
    FACEBOOK: 'https://www.facebook.com/erifranckN/',
    LINKEDIN: 'https://www.linkedin.com/in/erifranck-nu%C3%B1ez-948a32b7/',
  },
  toonfranck: {
    INSTAGRAM: 'https://www.instagram.com/toonfranck',
  },
  site: {
    INSTAGRAM: 'https://www.instagram.com/draw_mycode',
    TWITTER: 'https://twitter.com/@drawMyCode',
  },
}
